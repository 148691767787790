import axios from 'axios';
import settings from '../config/settings';

const axiosInstance = axios.create({
  baseURL: settings.api.url,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.defaults.headers.common["Authorization"] = settings.api.basicAuth;



axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers["access-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          console.error('Bad Request - 400');
          break;
        case 401:
          // localStorage.removeItem('accessToken');
          // localStorage.removeItem('userProfile');
          // window.location.href = '/auth/login';
          break;
        default:
          console.error(`Error: ${error.response.status}`);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
