import { Modal } from "react-bootstrap";
import { IconPrint } from "../SvgIcon";

const FedExDropOffReportModal = ({show, calback}) => {
    return(
        <Modal
            show={show}
            size="lg"
            onHide={calback}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="px-5">
                <div className="text-center">
                    <h3>FedEx Drop-Off Report</h3>
                    <p>This report lists all Fedex recorded within the specified
                    date range, along with dollar amounts and totals.</p>
                </div>

                <div className='card p-3 w-50 mx-auto mt-5 mb-5'>
                    <h5>Report Type</h5>
                    <div className='d-flex align-items-center justify-content-center mt-4'>
                        <p className='mb-0 me-2'>Regular</p>
                        <span className='cm_radio me-5'>
                            <input name='ReceiptPrinter' value={true} type='radio' />
                            <span />
                        </span>
                        
                        <p className='mb-0 me-2'>Excel</p>
                        <span className='cm_radio'>
                            <input name='ReceiptPrinter' value={false} type='radio' />
                            <span />
                        </span>
                       
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-4 mb-4">
                    <div className="btn_actions">
                        <span className="btn btn_sky px-4 btn-sm" onClick={calback}><IconPrint /> Print</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FedExDropOffReportModal;