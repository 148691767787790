import { Link } from "react-router-dom";
import CustomerCard from "../../components/common/CustomerCard";
import { getCustomerListService } from "../../service";
import { useEffect, useState } from "react";

const AllCustomerDetails = ({pageType}) => {

    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('');
    const [customers, setCustomers] = useState([]);
    const [totalCount, setTotalCount] = useState('');


    const limit = 12


    const getCustomerList = async () => {
        try {
            let query = `?search=${search}&page=${page}&limit=${limit}`;
            const res = await getCustomerListService(query);
            setCustomers(res.data.result);
            setTotalCount(res.data.count);
        } catch (error) {
            console.error("Error fetching customer list:", error);
        }
    };
    

    useEffect(() => {
        getCustomerList()
    }, [])


    return(
        <div className="body_wrapper">
            <div className="cm_body_header">
                <h5 className='mb-0'>Ship ({pageType}) {'>'} <b>All Customer Details</b></h5>
                <Link to={`/ship/${pageType}/add-customer-info`} className="btn btn-outline-dark ms-auto">+ Add new</Link>
            </div>


            <div className="container">
                <h5 className="my-5"><b>From</b> Select Existing Customer or Add New</h5>

                <div className="row">
                    {customers.map((item, i) => <div className="col-md-6 mb-4" key={item._id}><CustomerCard data={item} /></div>)}
                </div>
            </div>

        </div>
    )
}

export default AllCustomerDetails;