import { useDispatch, useSelector } from 'react-redux';

import { IconCrossSmall, IconFile, IconLeftArrow, IconRightArrow } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import { useState } from 'react';
import { addInventoryByFileService } from '../../service';
import { useNavigate } from 'react-router-dom';


const InventorySetup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userProfile = useSelector((state) => state.user);

    const [inventoryList, setInventoryList] = useState([]);
    const [inventoryListErr, setInventoryListErr] = useState([]);

    
    
    const handleSubmit = () => {
        if(inventoryList.length){
            dispatch(setUserProfileAction({...userProfile, isProfileComplete: true, tab: 8}));
            navigate('/')

        }else{
            setInventoryListErr('Please upload inventory')
        }
        
    }
    
    const handleBackPage = () => {
        dispatch(setUserProfileAction({...userProfile, tab: 6}));
    }


    const handleFileChange = async (e) => {
        setInventoryListErr('');
        try {
            let file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            const response = await addInventoryByFileService(formData);
            setInventoryList(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    


    return (
        <div className='page_wrapper'>
            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">5</span> Inventory Setup</h3>
                </div>

                <div className='container-fluid card'>

                    {
                        inventoryList.length ? <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Department</th>
                                        <th>Description</th>
                                        <th>Dimensions</th>
                                        <th>Location</th>
                                        <th>Low Reorder Alert</th>
                                        <th>Quantity</th>
                                        <th>Resell Price</th>
                                        <th>SKU</th>
                                        <th>Supplier</th>
                                        <th>Tax Category</th>
                                        <th>Weight</th>
                                        <th>Wholesale Cost</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        inventoryList.map(item => (
                                            <tr key={item._id}>
                                                <td>{item.productName}</td>
                                                <td>{item.department}</td>
                                                <td>{item.description}</td>
                                                <td>{item.dimensions}</td>
                                                <td>{item.location}</td>
                                                <td>{item.lowReorderAlert}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.resellPrice}</td>
                                                <td>{item.sku}</td>
                                                <td>{item.supplier}</td>
                                                <td>{item.taxCategory}</td>
                                                <td>{item.weight}</td>
                                                <td>{item.wholesaleCost}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div> : <div className={`image_uploader w-50 mx-auto my-5`}>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            value=""
                            accept=".xls, .xlsx" />
                            <div>
                                <IconFile />
                                <small>Click here to upload file</small>
                            </div>
                    </div>
                    }

                    <div>{inventoryListErr ? <h6 style={{fontSize: '14px', color: 'red', textAlign: 'center'}}>{inventoryListErr}</h6> : ''}</div>
                    
                </div>

                <div className="d-flex footer_navigate px-5">
                    <span onClick={handleBackPage} className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></span>
                    <span className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></span>
                    <span onClick={handleSubmit} className="btn btn-dark ms-5"><span className="me-3">Next</span> <IconRightArrow /></span>
                </div>
            </div>
        </div>
        
    )
}

export default InventorySetup;