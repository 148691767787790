import { configureStore } from '@reduxjs/toolkit';
import ulSlice from './uiSlice';
import userSlice from './userSlice';
import myStoreSlice from './myStoreSlice';

export const store = configureStore({
  reducer: {
    ui: ulSlice,
    user: userSlice,
    myStore: myStoreSlice,
  },
});