
const localhost = {
    api: {
        url: 'http://localhost:5001/api/',
        basicAuth: 'Basic cG9zdGFsLXByb3M6cG9zdGFsLXByb3M=',
        stripeToken: 'pk_test_51PTzWpIKDpvy5bAD7jslf7EpWzjXeZz8a4m5nbLIv6TOY8xRrLYqnSraO325J4wtgEc8WlLrIdWq48ijkblkt5xb009o5dah1b',
        mode: 'cors'
    },
}

const development = {
    api: {
        url: 'https://devapi.spacktacular.com/api/',
        basicAuth: 'Basic cG9zdGFsLXByb3M6cG9zdGFsLXByb3M=',
        stripeToken: 'pk_test_51PTzWpIKDpvy5bAD7jslf7EpWzjXeZz8a4m5nbLIv6TOY8xRrLYqnSraO325J4wtgEc8WlLrIdWq48ijkblkt5xb009o5dah1b',
        mode: 'cors'
    },
}

const staging = {
    api: {
        url: 'https://stagapi.spacktacular.com/api/',
        basicAuth: 'Basic cG9zdGFsLXByb3M6cG9zdGFsLXByb3M=',
        stripeToken: 'pk_test_51PTzWpIKDpvy5bAD7jslf7EpWzjXeZz8a4m5nbLIv6TOY8xRrLYqnSraO325J4wtgEc8WlLrIdWq48ijkblkt5xb009o5dah1b',
        mode: 'cors'
    },
}

const production = {
    api: {
        url: 'https://devapi.spacktacular.com/api/',
        basicAuth: 'Basic cG9zdGFsLXByb3M6cG9zdGFsLXByb3M=',
        stripeToken: 'pk_test_51PTzWpIKDpvy5bAD7jslf7EpWzjXeZz8a4m5nbLIv6TOY8xRrLYqnSraO325J4wtgEc8WlLrIdWq48ijkblkt5xb009o5dah1b',
        mode: 'cors'
    },
}


export default (() => {
    switch (process.env.REACT_APP_ENV) {
        case "development":
        case "dev":
        case "dev ":
            return development;
        case "staging":
        case "stag":
        case "stag ":
            return staging;
        case "production":
        case "prod":
        case "prod ":
            return production;
        case "localhost":
        case "local":
        case "local ":
            return localhost;
        default:
            return development;
    }
})();