import { useDispatch, useSelector } from 'react-redux';

import { IconCrossSmall, IconLeftArrow, IconRightArrow, IconSpiner } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import { Fragment, useEffect, useState } from 'react';

import FedEx from '../../assets/images/FedEx.svg';
import UPS from '../../assets/images/UPS.svg';
import USPS from '../../assets/images/USPS.svg';
import DHL from '../../assets/images/DHL.svg';
import FedExSubscriptionModal from '../../components/common/FedExSubscriptionModal';
import FedExWholesaleRatesModal from '../../components/common/FedExWholesaleRatesModal';
import FedExDropOffReportModal from '../../components/common/FedExDropOffReportModal';
import { addCarrierSettingService } from '../../service';
import { updateCarrierAction } from '../../store/myStoreSlice';


const serviceImages = { FedEx, UPS, USPS, DHL, Others: ''};







const CarrierSetup = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user);
    const store = useSelector((state) => state.myStore.store);
    const carrierFromStore = useSelector((state) => state.myStore.carrier);

    const [carriers, setCarriers] = useState(demo_carriers);

    const [activeServiceTab, setActiveServiceTab] = useState('FedEx');
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [wholesaleRatesModal, setWholesaleRatesModal] = useState(false);
    const [dropOffReportModal, setDropOffReportModal] = useState(false);

    const [btnLoader, setBtnLoader] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [hasChanged, setHasChanged] = useState(false);


    useEffect(() => {
        if(carrierFromStore._id){
            console.log(carrierFromStore, '-----carrierFromStorecarrierFromStore')
            const arr1Lookup = carrierFromStore.carriers.reduce((lookup, item) => {
                lookup[item.name] = item;
                return lookup;
            }, {});
            
            const mergedArray = demo_carriers.map(item => arr1Lookup[item.name] || item);
            
            setCarriers(mergedArray);
        }
    }, [])



    const handleValidate = () => {
        let validate = true;

        return validate
    }


    const handleSubmit = async () => {
        if (handleValidate()) {
            setBtnLoader(true);
            const params = {
                carriers: carriers.filter(item => item.account)
            };

            try {
                if(hasChanged){
                    const res = await addCarrierSettingService(params);
                    dispatch(setUserProfileAction({...userProfile, tab: 5}));
                    dispatch(updateCarrierAction(res.data));
                }else{
                    dispatch(setUserProfileAction({ ...userProfile, tab: 5 }));
                }
            } catch (error) {
                setResMsg(error?.response?.data?.error || 'An error occurred during login.');
                console.error('Error adding carrier service:', error);
            }
            setBtnLoader(false);
        }
    };
    
    
    
    const handleBackPage = () => {
        dispatch(setUserProfileAction({...userProfile, tab: 3}));
    }


    const handleServiceSelect = (e, carrierInd, serviceInd) => {
        const newCarriers = carriers.map((carrier, cIndex) => {
            if (cIndex === carrierInd) {
                const newServices = carrier.services.map((service, sIndex) => {
                    if (sIndex === serviceInd) {
                        return {
                            ...service,
                            enabled: !service.enabled,
                        };
                    }
                    return service;
                });
                return {
                    ...carrier,
                    services: newServices,
                };
            }
            return carrier;
        });
        setCarriers(newCarriers);
        setHasChanged(true);
    };


    const handleAccountChange = (e, carrierIndex) => {
        const newCarriers = carriers.map((carrier, cIndex) => {
            if (cIndex === carrierIndex) {
                return {
                    ...carrier,
                    account: e.target.value,
                };
            }
            return carrier;
        });
        setCarriers(newCarriers);
        setHasChanged(true);
    };


    return (
        <div className='page_wrapper'>
            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">3</span> Carrier Setup</h3>
                </div>


                    <div className='container card p-4'>
                        <div className=''>
                            {['FedEx', 'UPS', 'USPS', 'DHL', 'Others'].map((carrier, ind) => (
                                <span
                                    key={(carrier)}
                                    onClick={() => setActiveServiceTab(carrier)}
                                    className={`me-3 position-relative btn btn-${activeServiceTab === carrier ? 'primary' : 'light'}`}>
                                        {carrier}
                                        {carriers[ind].account ? <b className='green_check'></b> : ''}
                                    </span>
                            ))}
                        </div>

                        {activeServiceTab !== 'Others' && (
                            <div className='service_brand mt-5'>
                                <img src={serviceImages[activeServiceTab]} alt={activeServiceTab} />
                            </div>
                        )}


                        {
                            carriers.map((carrierItem, carrierIndex) => (
                                <Fragment>
                                    {
                                        carrierItem.name === activeServiceTab && <Fragment>
                                            <div className='row mt-5 pb-4' key={carrierIndex}>
                                                <div className='col-md-5 pe-5'>
                                                    <div className='card p-3'>
                                                        <h5>Enable Sevices</h5>
                                                        <div className='d-flex align-items-center justify-content-between px-4 mt-3'>
                                                            {
                                                                carrierItem.services.map((serviceItem, serviceIndex) => (
                                                                    <div className='d-flex align-items-center' key={serviceIndex}>
                                                                        <span className='cm_checkbox'>
                                                                            <input
                                                                                value="FedEx Express"
                                                                                checked={serviceItem.enabled}
                                                                                onChange={e => handleServiceSelect(e, carrierIndex, serviceIndex)}
                                                                                type='checkbox' />
                                                                            <span />
                                                                        </span>
                                                                        <p className='mb-0 ms-2'>{serviceItem.name}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='card p-3 mt-5'>
                                                        <h5>FedEx Account</h5>
                                                        <div className='mt-3 ps-4'>
                                                            <input
                                                                type="text"
                                                                value={carrierItem.account}
                                                                onChange={(e) => handleAccountChange(e, carrierIndex)}
                                                                className='form-control'
                                                                placeholder='Enter here' />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='col-md-7 ps-5'>
                                                    <div className='card p-3 mb-4'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='mb-0'>Online Shipping Status:</h5>
                                                            {carrierItem.onlineShippingStatus ? <p className='mb-0 text-success'>Subscription current</p> : <p className='mb-0'>Not Subscribed</p>}
                                                            <span className='cm_link' onClick={() => setSubscriptionModal(true)}>(Change)</span>
                                                        </div>
                                                    </div>


                                                    <div className='card'>
                                                        <div className='d-flex justify-content-between align-items-center py-2 px-3 cm_bg_light_gray cm_radius_top'>
                                                            <h5 className='mb-0'>Wholesale Rates</h5>
                                                            <span className='cm_link' onClick={() => setWholesaleRatesModal(true)}>Select Tier</span>
                                                        </div>

                                                        <div className='p-3'>
                                                            <span className='pe-5'>FASC Express Tier:  - <b className='ms-4'>{carrierItem.wholesaleRates.expressTier}</b></span>
                                                            <span className='ms-5'>FASC Ground Tier:  - <b className='ms-4'>{carrierItem.wholesaleRates.groundTier}</b></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-end mt-5'>
                                                        <span className='cm_link_black' onClick={() => setDropOffReportModal(true)}>Drop Off Report Link</span>
                                                    </div>

                                                    {resMsg ? <h6 className="res_err mb-3">{resMsg}</h6> : ''}


                                                </div>
                                            </div>

                                            <FedExSubscriptionModal
                                                show={subscriptionModal}
                                                calbackSubscribe={(e) => {
                                                    const newCarriers = [...carriers];
                                                    newCarriers[carrierIndex].onlineShippingStatus = e;
                                                    setCarriers(newCarriers);
                                                    setSubscriptionModal(false);
                                                    setHasChanged(true);
                                                }}
                                                calback={() => setSubscriptionModal(false)} />

                                            <FedExWholesaleRatesModal
                                                show={wholesaleRatesModal}
                                                calback={() => setWholesaleRatesModal(false)}
                                                calbackTier={(e) => {
                                                    const newCarriers = [...carriers];
                                                    newCarriers[carrierIndex].wholesaleRates.expressTier = e;
                                                    newCarriers[carrierIndex].wholesaleRates.groundTier = e;
                                                    setCarriers(newCarriers);
                                                    setWholesaleRatesModal(false);
                                                    setHasChanged(true);
                                                }} />
                                        </Fragment>
                                    }
                                    
                                </Fragment>
                            ))
                        }


                        
                    </div>
               


    
                <div className="d-flex footer_navigate px-5">
                    <span onClick={handleBackPage} className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></span>
                    <span className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></span>
                    <span
                        onClick={() => btnLoader ? null : handleSubmit()}
                        className="btn btn-dark ms-5">
                        <span className="me-3">Next</span> {btnLoader ? <IconSpiner /> : <IconRightArrow />}
                    </span>
                </div>
            </div>


            

            <FedExDropOffReportModal show={dropOffReportModal} calback={() => setDropOffReportModal(false)} />
        </div>
        
    )
}

export default CarrierSetup;




const demo_carriers = [
    {
        name: 'FedEx',
        services: [
            {name: 'FedEx Express', enabled: false},
            {name: 'FedEx Ground', enabled: false},
        ],
        account: '',
        onlineShippingStatus: false,
        wholesaleRates: {
            expressTier: '',
            groundTier: ''
        }
    },
    {
        name: 'UPS',
        services: [
            {name: 'UPS Air', enabled: false},
            {name: 'UPS Ground', enabled: false},
        ],
        account: '',
        onlineShippingStatus: false,
        wholesaleRates: {
            expressTier: '',
            groundTier: ''
        }
    },
    {
        name: 'USPS',
        services: [
            {name: 'USPS Priority', enabled: false},
            {name: 'USPS First Class', enabled: false},
        ],
        account: '',
        onlineShippingStatus: false,
        wholesaleRates: {
            expressTier: '',
            groundTier: ''
        }
    },
    {
        name: 'DHL',
        services: [
            {name: 'DHL Express', enabled: false},
            {name: 'DHL Parcel', enabled: false},
        ],
        account: '',
        onlineShippingStatus: false,
        wholesaleRates: {
            expressTier: '',
            groundTier: ''
        }
    },
    {
        name: 'Others',
        services: [],
        account: '',
        onlineShippingStatus: false,
        wholesaleRates: {
            expressTier: '',
            groundTier: ''
        }
    },
]