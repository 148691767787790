import { Fragment, useState } from "react";
import { IconAddBox, IconDelete } from "../SvgIcon";
import { Modal } from "react-bootstrap";
import { formatTime } from "../../helper";


const DynamicPricingFrom = ({time_slots, calback}) => {
    const [list, setList] = useState(time_slots.length ? time_slots : []);
    const [addSlotModal, setAddSlotModal] = useState(false);
    const [slotStartTime, setSlotStartTime] = useState('');
    const [slotEndTime, setSlotEndTime] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => {
        setAddSlotModal(false);
        setSlotStartTime('');
        setSlotEndTime('');
        setError('');
    };

    const handleAddTimeSlot = () => {
        if (!slotStartTime || !slotEndTime) {
            setError('Start time and end time are required.');
            return;
        }
        const newSlot = {
            start_time: formatTime(slotStartTime),
            end_time: formatTime(slotEndTime),
            rates: {
                mon: '',
                tue: '',
                wed: '',
                thu: '',
                fri: '',
                sat: '',
                sun: ''
            }
        };

        let _list = [...list];
        _list.push(newSlot);
        setList(_list);
        calback(_list);
        handleClose();
    };



    const handleChange = (e, index, day) => {
        const { value } = e.target;
        setList(prevList => {
            const newList = [...prevList];
            newList[index] = {
                ...newList[index],
                rates: {
                    ...newList[index].rates,
                    [day]: value
                }
            };
            calback(newList);
            return newList;
        });
    };
    

    const handleDeleteSlot = (index) => {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        calback(updatedList);
    };

    return (
        <Fragment>
                <div className="dpb">
                    <div className="dpb_row">
                        <div className="dpb_time"></div>
                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => <div className="dpb_day" key={day}>{day}</div>)}
                        <div className="dpb_action"></div>
                    </div>

                    {list.map((item, index) => (
                        <div className="dpb_row" key={index}>
                            <div className="dpb_time">{item.start_time} - {item.end_time}</div>

                            {Object.keys(item.rates).map((day) => (
                                <div className="dpb_day" key={day}>
                                    <input
                                        onChange={e => handleChange(e, index, day)}
                                        style={{maxWidth: '74px'}}
                                        type='number'
                                        className='form-control w-auto mx-auto'
                                        value={item.rates[day]}/>
                                </div>
                            ))}
                            <div className="dpb_action">
                                <span onClick={() => handleDeleteSlot(index)}><IconDelete /></span>
                            </div>
                        </div>
                    ))}
                    <div onClick={() => setAddSlotModal(true)} className="add_time_slots_btn"><IconAddBox /> <span className="ms-2">Add Slot</span></div>
                </div>


            <Modal
                show={addSlotModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="form-group mb-4">
                        <label>Start Time</label>
                        <input
                            type="time"
                            value={slotStartTime}
                            onChange={(e) => setSlotStartTime(e.target.value)}
                            className="form-control"
                            placeholder="Start Time" />
                    </div>
                    <div className="form-group mb-5">
                        <label>End Time</label>
                        <input
                            type="time"
                            value={slotEndTime}
                            onChange={(e) => setSlotEndTime(e.target.value)}
                            className="form-control"
                            placeholder="End Time" />
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <span onClick={handleAddTimeSlot} className="btn btn-primary d-block">Add</span>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default DynamicPricingFrom;
