import axios from "axios";
import { API } from "../config/api";
import axiosInstance from "./interceptor";

const isMultiPart = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
}

export const userSignupService = (params) => axiosInstance.post(API.USER_SIGNUP, params)

export const printerListService = () => axiosInstance.post(API.PRINTER_LIST)

export const updateUserTokenService = () => axiosInstance.post(API.UPDATE_USER_TOKEN)

export const loginService = (params) => axiosInstance.post(API.USER_LOGIN, params)

export const forgetPasswordService = (params) => axiosInstance.post(API.FORGET_PASSWORD, params)

export const getUserProfileService = () => axiosInstance.get(API.GET_USER_PROFILE)

export const addStoreService = (params) => axiosInstance.post(API.ADD_STORE, params)

export const getStoreService = () => axiosInstance.get(API.GET_STORE)

export const uploadFileService = (params) => axiosInstance.post(API.UPLOAD_FILE, params, isMultiPart)

export const addInventoryByFileService = (params) => axiosInstance.post(API.ADD_INVENTORY_BY_FILE, params, isMultiPart)

export const addShippingService = (params) => axiosInstance.post(API.ADD_SHIPPING, params)

export const getShippingService = (params) => axiosInstance.get(API.GET_SHIPPING)

export const addDropSettingService = (params) => axiosInstance.post(API.ADD_DROP_SETTING, params)

export const getDropSettingService = () => axiosInstance.get(API.GET_DROP_SETTING)

export const addCarrierSettingService = (params) => axiosInstance.post(API.ADD_CARRIER_SETTING, params)

export const getCarrierSettingService = () => axiosInstance.get(API.GET_CARRIER_SETTING)

export const getCustomerListService = query => axiosInstance.get(API.GET_CUSTOMER_LIST + query)

export const addCustomerService = params => axiosInstance.post(API.ADD_CUSTOMER, params)