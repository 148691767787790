import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";

import StoreCustomisation from "./StoreCustomisation";
import EquipmentSetup from "./EquipmentSetup";
import PrinterSetup from "./PrinterSetup";
import CarrierSetup from "./CarrierSetup";
import ShippingSetup from "./ShippingSetup";
import DropOffSetup from "./DropOffSetup";
import InventorySetup from "./InventorySetup";
import { getCarrierSettingService, getStoreService, getShippingService, getDropSettingService } from "../../service";
import { updateCarrierAction, updateDropOffAction, updateShippingAction, updateStoreAction } from "../../store/myStoreSlice";
import { useNavigate } from "react-router-dom";

const componentsMap = {
    1: StoreCustomisation,
    2: EquipmentSetup,
    3: PrinterSetup,
    4: CarrierSetup,
    5: ShippingSetup,
    6: DropOffSetup,
    7: InventorySetup,
};

const Onboarding = () => {
    const userProfile = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ActiveComponent = componentsMap[userProfile.tab];

    const getStore = async () => {
        try {
            const res = await getStoreService();
            dispatch(updateStoreAction(res.data))
        } catch (error) {
            console.error('Error fetching store:', error);
        }
    };


    const getCarrierSetting = async () => {
        try {
            const res = await getCarrierSettingService();
            dispatch(updateCarrierAction(res.data))
        } catch (error) {
            console.error('Error fetching Carrier setting:', error);
        }
    };


    const getShipping = async () => {
        try {
            const res = await getShippingService();
            dispatch(updateShippingAction(res.data))
        } catch (error) {
            console.error('Error fetching Shipping setting:', error);
        }
    };

    const getDropSetting = async () => {
        try {
            const res = await getDropSettingService();
            dispatch(updateDropOffAction(res.data))
        } catch (error) {
            console.error('Error fetching Drop Off setting:', error);
        }
    };

    useEffect(() => {
        getStore();
        getCarrierSetting();
        getShipping();
        getDropSetting();

        if(userProfile.isProfileComplete){
            navigate('/');
        }
    }, [])

    return (
        <Fragment>
            {ActiveComponent && <ActiveComponent />}
        </Fragment>
    );
}

export default Onboarding;
