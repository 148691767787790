import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IconCrossSmall, IconImageUpload, IconLeftArrow, IconRightArrow, IconSpiner } from "../../components/SvgIcon";
import CustomPhoneInput from "../../components/common/CustomPhoneInput";
import { colorList, countryList } from "../../constant";
import { addStoreService, getStoreService, updateUserTokenService, uploadFileService } from "../../service";
import { setUserProfileAction } from "../../store/userSlice";
import { Toast } from "react-bootstrap";
import MyToast from "../../components/common/MyToast";
import { emailRegex } from '../../helper';
import { updateStoreAction } from "../../store/myStoreSlice";
import axios from "axios";

const StoreCustomisation = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user);
    const store = useSelector((state) => state.myStore.store);
    const [hasChanged, setHasChanged] = useState(false);
    const [storeLogo, setStoreLogo] = useState('');
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [isThemeColor, setIsThemeColor] = useState(false);
    const [resMsg, setResMsg] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        zipcode: '',
        state: '',
        country: '',
        primaryColor: '',
        secondaryColor: '',
        storeLogoView: null
    });
    


    useEffect(() => {
        if(store._id){
            setFormData({
                ...store,
                storeLogoView: store.storeLogo,
            });
            setIsThemeColor(store.primaryColor ? true : false)
        }
    }, [])

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        setErrors({ ...errors, [field]: '' });
        setHasChanged(true);
    };

    const handleFileChange = (event) => {
        setHasChanged(true);
        const selectedFile = event.target.files[0];
        setStoreLogo(event.target.files[0]);
        if (selectedFile) {
            const previewURL = URL.createObjectURL(selectedFile);
            handleChange('storeLogoView', previewURL);
        }
    };

    const handleValidate = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Store name is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.addressLineOne) newErrors.addressLineOne = 'Address line 1 is required';
        if (!formData.addressLineTwo) newErrors.addressLineTwo = 'Address line 2 is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.zipcode) newErrors.zipcode = 'Zip code is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.country) newErrors.country = 'Country is required';

        if (isThemeColor) {
            if (!formData.primaryColor) newErrors.primaryColor = 'Primary color is required';
            if (!formData.secondaryColor) newErrors.secondaryColor = 'Secondary color is required';
        }

        if (!formData.storeLogoView) newErrors.storeLogoView = 'Store logo is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async () => {
        if (!handleValidate()) return;
    
        setBtnLoader(true);
    
        try {
            const params = { ...formData };
            if (storeLogo) {
                const uploadFormData = new FormData();
                uploadFormData.append('file', storeLogo);
                const imageData = await uploadFileService(uploadFormData);
                params.storeLogo = imageData.data.file_url;
            }
            const fieldsToRemove = ['storeLogoView', 'createdAt', 'updatedAt', '__v'];
            fieldsToRemove.forEach(field => delete params[field]);
    
            if (hasChanged) {
                const res = await addStoreService(params);
                dispatch(updateStoreAction(res.data));
                const newToken = await updateUserTokenService();
                localStorage.setItem('accessToken', newToken.data.token);
                axios.defaults.headers.common["access-token"] = newToken.data.token;
            }
    
            dispatch(setUserProfileAction({ ...userProfile, tab: 2 }));
    
        } catch (serviceError) {
            setResMsg(serviceError?.response?.data?.error);
        } finally {
            setBtnLoader(false);
        }
    };
    


    return (
        <div className="page_wrapper">
            <MyToast msg={resMsg} calback={() => setResMsg('')} />

            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">1</span> Store Customisation</h3>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pe-lg-4">
                            <div className="form-group mb-4">
                                <input
                                    type='text'
                                    value={formData.name}
                                    onChange={e => handleChange('name', e.target.value)}
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    placeholder='Store Name' />
                                {errors.name && <span className='invalid-feedback'>{errors.name}</span>}
                            </div>

                            <div className="form-group mb-4">
                                <CustomPhoneInput
                                    placeholder="Phone Number"
                                    val={formData.phone}
                                    err={errors.phone}
                                    handlePhoneChange={e => handleChange('phone', e.target.value)} />
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type='email'
                                    placeholder='Store Email'
                                    value={formData.email}
                                    onChange={e => handleChange('email', e.target.value)}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                {errors.email && <span className='invalid-feedback'>{errors.email}</span>}
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    placeholder="Store Address Line 1"
                                    value={formData.addressLineOne}
                                    onChange={e => handleChange('addressLineOne', e.target.value)}
                                    className={`form-control ${errors.addressLineOne ? 'is-invalid' : ''}`} />
                                {errors.addressLineOne && <span className='invalid-feedback'>{errors.addressLineOne}</span>}
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    placeholder="Store Address Line 2"
                                    value={formData.addressLineTwo}
                                    onChange={e => handleChange('addressLineTwo', e.target.value)}
                                    className={`form-control ${errors.addressLineTwo ? 'is-invalid' : ''}`} />
                                {errors.addressLineTwo && <span className='invalid-feedback'>{errors.addressLineTwo}</span>}
                            </div>
                            

                            <div className="form-group mb-4">
                                <select
                                    value={formData.country}
                                    onChange={e => handleChange('country', e.target.value)}
                                    className={`form-control ${errors.country ? 'is-invalid' : ''}`}>
                                    <option value="" disabled hidden>Country</option>
                                    {countryList.map((item, i) => <option key={i} value={item.name}>{item.name}</option>)}
                                </select>
                                {errors.country && <span className='invalid-feedback'>{errors.country}</span>}
                            </div>
                            

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    placeholder="State"
                                    value={formData.state}
                                    onChange={e => handleChange('state', e.target.value)}
                                    className={`form-control ${errors.state ? 'is-invalid' : ''}`} />
                                {errors.state && <span className='invalid-feedback'>{errors.state}</span>}
                            </div>

                            <div className="row">
                                <div className="col-6 form-group mb-4">
                                    <input
                                        type="text"
                                        placeholder="City"
                                        value={formData.city}
                                        onChange={e => handleChange('city', e.target.value)}
                                        className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                    {errors.city && <span className='invalid-feedback'>{errors.city}</span>}
                                </div>

                                <div className="col-6 form-group mb-4">
                                    <input
                                        type="text"
                                        placeholder="Zip Code"
                                        value={formData.zipcode}
                                        onChange={e => handleChange('zipcode', e.target.value)}
                                        className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} />
                                    {errors.zipcode && <span className='invalid-feedback'>{errors.zipcode}</span>}
                                </div>
                            </div>

                            

                           
                        </div>

                        <div className="col-lg-6 ps-lg-4">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-0">Enter your theme color</h5>
                                <span className="cm_toggler">
                                    <input
                                        checked={isThemeColor}
                                        onChange={() => setIsThemeColor(!isThemeColor)}
                                        type="checkbox" />
                                    <span />
                                </span>
                            </div>

                            <div className={`color_select_box mt-3 ${isThemeColor ? 'active' : ''}`}>
                                <div>
                                    <small><b>Primary color</b></small>
                                    <div style={{ background: formData.primaryColor }} className="color_box"></div>
                                    <select
                                        disabled={!isThemeColor}
                                        className="form-control"
                                        value={formData.primaryColor}
                                        onChange={e => handleChange('primaryColor', e.target.value)}>
                                        <option>Select Color</option>
                                        {colorList.map((item, i) => <option value={item} key={i}>{item}</option>)}
                                    </select>
                                </div>

                                <div>
                                    <small><b>Secondary color</b></small>
                                    <div style={{ background: formData.secondaryColor }} className="color_box"></div>
                                    <select
                                        className="form-control"
                                        disabled={!isThemeColor}
                                        value={formData.secondaryColor}
                                        onChange={e => handleChange('secondaryColor', e.target.value)}>
                                        <option>Select Color</option>
                                        {colorList.map((item, i) => <option value={item} key={i}>{item}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className={`image_uploader mt-3 ${errors.storeLogoView ? 'is-invalid' : ''}`}>
                                <input
                                    type="file"
                                    value=""
                                    accept="image/*"
                                    onChange={handleFileChange} />
                                {formData.storeLogoView ? <img src={formData.storeLogoView} alt="Store Logo" /> : (
                                    <div>
                                        <IconImageUpload />
                                        <small>Click here to upload Store logo</small>
                                    </div>
                                )}
                            </div>
                            {errors.storeLogoView && <span className='invalid-feedback'>{errors.storeLogoView}</span>}
                        </div>
                    </div>
                </div>

                <div className="d-flex footer_navigate px-5">
                    <button className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></button>
                    <button className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></button>
                    <button
                        onClick={() => btnLoader ? null : handleSubmit()}
                        className="btn btn-dark ms-5">
                        <span className="me-3">Next</span> {btnLoader ? <IconSpiner /> : <IconRightArrow />}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StoreCustomisation;