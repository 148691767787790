import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import BrandLogo from '../../assets/images/brand-logo.png'
import { useEffect, useState } from "react";
import { loginService } from "../../service";
import { setUserProfileAction } from "../../store/userSlice";
import { emailRegex } from "../../helper";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logoLoader, setLogoLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [showPass, setShowPass] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setLogoLoader(false)
        }, 1500)
    }, [])



    const handleValidate = () => {
        let validate = true;

        if (!email.trim()) {
          setEmailErr('Email is required');
          validate = false;
        } else if (!emailRegex.test(email)) {
          setEmailErr('Invalid email format');
          validate = false;
        }


        if (!password.trim()) {
            setPasswordErr('Password is required');
            validate = false;
          } else if (password.length < 4) {
            setPasswordErr('Password must be at least 6 characters long');
            validate = false;
          }

          return validate;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResMsg('');
    
        if (handleValidate()) {
            setBtnLoader(true);
            const params = { email, password };
            try {
                const res = await loginService(params);
                dispatch(setUserProfileAction({...res.data.user}));
                localStorage.setItem('accessToken', res.data.token);
                localStorage.setItem('userProfile', JSON.stringify(res.data.user));
                const destination = res.data.user.isProfileComplete ? '/' : '/onboarding';
                navigate(destination);
            } catch (serviceError) {
                setResMsg(serviceError?.response?.data?.error || 'An error occurred during login.');
            } finally {
                setBtnLoader(false);
            }
        }
    };


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if(token){
            navigate('/');
        }
    }, [])
    

    return (
        <div className="min-vh-100 d-flex bg-white align-items-center">
            <div className={`auth_logo_wrapper ${logoLoader ? 'logo_loader' : ''}`}>
               <div>
                    <img src={BrandLogo} alt="Postalpros" />
                    <div className="dots_animate">
                        <span className="dot_1" />
                        <span className="dot_2" />
                        <span className="dot_3" />
                        <span className="dot_4" />
                    </div>
               </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-5">
                        <form className="p-5" onSubmit={handleSubmit}>
                            <div className='cm_hidden'>
                                <input type='email' name="email" id="email" />
                                <input type='password' name="password" id="password"  />
                            </div>
                            <h2 className="mb-4 fw-bolder">Log In</h2>

                            <div className="mb-4">
                                <input
                                    type='email'
                                    value={email}
                                    onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                    className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                    placeholder='Enter Email' />

                                {emailErr && <span className='invalid-feedback'>{emailErr}</span>}
                            </div>

                            <div className="mb-4 position-relative">
                                <div onClick={() => setShowPass(!showPass)} className="show_pass">{showPass ? 'Hide' : 'Show' } </div>
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    value={password}
                                    onChange={e => (setPassword(e.target.value), setPasswordErr(''))}
                                    className={`form-control ${passwordErr ? 'is-invalid' : ''}`}
                                    placeholder='Password' />
                                {passwordErr && <span className='invalid-feedback'>{passwordErr}</span>}
                            </div>

                            <div className="d-flex justify-content-end mb-5">
                                <Link to="/auth/forget-password">Forget Password</Link>
                            </div>

                            {resMsg ? <h6 className="res_err mb-3">{resMsg}</h6> : ''}
                            <button
                                type="submit" 
                                disabled={btnLoader}
                                className="btn btn-primary w-100 py-3"> {btnLoader ? 'Logging in...' : 'Login'}</button>

                            <div className="mt-5 text-center">
                                Don’t have an account?<Link to="/auth/signup"> Register Now</Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;