import { useEffect } from "react";
import { IconCrossSmall } from "../SvgIcon";

const MyToast = ({ msg, calback }) => {
    useEffect(() => {
        if (msg) {
            const timer = setTimeout(calback, 5000);
            return () => clearTimeout(timer);
        }
    }, [msg, calback]);

    if (!msg) return null;

    return (
        <div className="cm_toast">
            <span onClick={calback}>
                <IconCrossSmall />
            </span>
            <p>{msg}</p>
        </div>
    );
};

export default MyToast;
