import { useState, useRef, useEffect } from "react";
import InputMask from 'react-input-mask';

import { countryList } from "../../constant";
import { IconDownAngle } from "../SvgIcon";


const CustomPhoneInput = ({val, handlePhoneChange, placeholder, err}) => {
    const [selectedCountry, setSelectedCountry] = useState({
        "name": "United States",
        "dial_code": "+1",
        "code": "US"
    });

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setShowDropdown(false);
        setSearchQuery('');
        // if (inputRef.current) {
        //     inputRef.current.focus();
        // }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown]);

    const filteredCountries = countryList.filter(country => 
        country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="cm_phone_input" ref={dropdownRef}>
            <div className="cm_p_drop">
                <div className="selected_country" onClick={() => setShowDropdown(!showDropdown)}>
                    <img src={require(`../../assets/country-flags/${selectedCountry.code.toLowerCase()}.webp`)} alt={selectedCountry.name} />
                    <b>{selectedCountry.dial_code}</b>
                    <IconDownAngle />
                </div>
                {showDropdown && (
                    <div className="cm_p_drop_list px-3">
                        <input 
                            type="text" 
                            className="form-control form-control-sm mb-3"
                            placeholder="Search country..." 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} 
                        />
                        <div className="phone_cm_over_flow">
                            {filteredCountries.map((item, i) => (
                            <span key={i} onClick={() => handleCountrySelect(item)}>
                                <img src={require(`../../assets/country-flags/${item.code.toLowerCase()}.webp`)} alt={item.name} />
                                <b>{item.name}</b> {item.dial_code}
                            </span>
                            ))}
                        </div>
                        
                    </div>
                )}
            </div>

            <InputMask
                mask="999 999 9999"
                placeholder={placeholder}
                ref={inputRef}
                value={val}
                onChange={e => handlePhoneChange(e)}
                className={`form-control cm_phone_text ${err ? 'is-invalid' : ''}`}
                maskChar=" " />
            {err && <span className='invalid-feedback'>{err}</span>}
        </div>
    );
};

export default CustomPhoneInput;