import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const FedExSubscriptionModal = ({show, calback, calbackSubscribe}) => {

    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [termsAndConditionsErr, setTermsAndConditionsErr] = useState(false);

    const handleSubscribe = () => {
        if(termsAndConditions){
            calbackSubscribe(true)
        }else{
            setTermsAndConditionsErr('Please Checked Terms & Conditions')
        }
    }


    return(
        <Modal
            show={show}
            size="lg"
            onHide={calback}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="px-5">
                <h3>FedEx Subscription</h3>
                <p>This process will register your system with the FedEx Data Center, which will enable your system for FedEx
                Exoress Online activities.</p>

                <div className='card p-3'>
                    <h5>Check the following</h5>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Store Name</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">Pack & Ship</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Contact Name</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">Robert Jone</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Address</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">Random Street</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">City</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">San Marcos</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">State</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">CA</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Country</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">US</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Zip Code</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">92069</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">Phone</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">908-675-4356</p></div>
                    <div className="d-flex mb-2 ps-5"><div className="w-40">FedEx Account Number</div><b className="me-4">:</b><p className="mb-0 w-50 fw-bold">8739782738</p></div>
                </div>

                <div className="d-flex justify-content-between mt-4 mb-4">
                    <div className="d-flex align-items-center position-relative">
                        
                        <span className="cm_checkbox me-2">
                            <input
                                type="checkbox"
                                checked={termsAndConditions}
                                onChange={() => (setTermsAndConditions(!termsAndConditions), setTermsAndConditionsErr(''))} />
                            <span />
                        </span> I accept <Link className="" to="/"><b className="fw-bold ms-2">Terms & Conditions</b></Link>

                        {termsAndConditionsErr ? <span className="cm_err position-absolute" style={{bottom: '-20px', width: '280px', left: '27px', fontSize: '14px', color: 'red'}}>{termsAndConditionsErr}</span> : ''}
                    </div>

                    <div className="btn_actions">
                        <span className="btn btn-outline-dark px-4 btn-sm me-3" onClick={() => calbackSubscribe(false)}>Cancel</span>
                        <span className="btn btn_sky px-4 btn-sm" onClick={handleSubscribe}>Subscribe</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FedExSubscriptionModal;