import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';

import signupBg from '../../assets/images/signup-bg.png'
import { IconBank, IconCard, IconCvv, IconSpiner } from "../../components/SvgIcon";
import { userSignupService } from "../../service";
import { emailRegex } from "../../helper";


const cm_style = {
    base: {
        fontSize: '16px',
        fontFamily: `"Open Sans", sans-serif`,
        color: '#424770',
        '::placeholder': {
            color: '#C3C3C3',
            opacity: 1
        },
    },
    invalid: {
        color: '#9e2146',
    },
}



const Signup = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState('');
    const [cardName, setCardName] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [cardNameErr, setCardNameErr] = useState('');
    const [cardNumberErr, setCardNumberErr] = useState('');
    const [cardExpiryErr, setCardExpiryErr] = useState('');
    const [cardCvcErr, setCardCvcErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [signup_sucess, setSignup_sucess] = useState(false);


    const handleValidate = () => {
        let validate = true;

        if (!email.trim()) {
          setEmailErr('Email is required');
          validate = false;
        } else if (!emailRegex.test(email)) {
          setEmailErr('Invalid email format');
          validate = false;
        }

        if (!cardName) {
            setCardNameErr('Name is required');
            validate = false;
        }

        return validate;
    }


    const handleSubmit = async (e) => {
        setResMsg('');
        e.preventDefault();
    
        if (!handleValidate()) return;
        
        if (!stripe || !elements) return;
    
        
        const cardElement = elements.getElement(CardNumberElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        
        if (error) {
            console.error('[error]', error);
        } else if (paymentMethod && paymentMethod.id) {
            const params = {
                email,
                payment_method_id: paymentMethod.id,
            };
            try {
                setBtnLoader(true);
                const res = await userSignupService(params);
                setSignup_sucess(true);
            } catch (serviceError) {
                setResMsg(serviceError?.response?.data?.error || 'An error occurred during signup.');
            } finally {
                setBtnLoader(false);
            }
        }

    };


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if(token){
            navigate('/');
        }
    }, [])
    


    return (
        <div className="min-vh-100 d-flex bg-white align-items-center">
            <div style={{backgroundImage: `url(${signupBg})`}} className={`auth_logo_wrapper`}></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-5">
                        <form className="p-xl-5" onSubmit={handleSubmit}>
                            <h2 className="mb-4 fw-bolder">Register Yourself</h2>

                            <div className="mb-4">
                                <input
                                    type='email'
                                    value={email}
                                    onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                    className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                    placeholder='Enter your email' />

                                {emailErr && <span className='invalid-feedback'>{emailErr}</span>}
                            </div>


                            <h5 className="mb-4">Payment Method</h5>
                            <div className="d-flex mb-4 ac_options">
                                <span className={`btn btn-outline-secondary text-start w-100 active`}><IconCard />Card</span>
                                <span className={`btn btn-outline-secondary text-start w-100 ms-3`}><IconBank />US Bank Account</span>
                            </div>



                            <h5 className="mb-4">Card Information</h5>

                            <div>
                                <div className="mb-4">
                                <CardNumberElement
                                    className={`form_control form-control ${cardNumberErr ? 'is-invalid' : ''}`}
                                    onChange={e => setCardNumberErr(e?.error?.message)}
                                    options={{placeholder: 'Enter Card Number', showIcon: true, style: cm_style}} />

                                {cardNumberErr && <div className="invalid-feedback">{cardNumberErr}</div>}

                                </div>
                                <div className="row">
                                    <div className="col-6 mb-4">
                                        <CardExpiryElement
                                            className={`form_control form-control ${cardExpiryErr ? 'is-invalid' : ''}`}
                                            onChange={e => setCardExpiryErr(e?.error?.message)}
                                            options={{style: cm_style}} />

                                        {cardExpiryErr && <div className="invalid-feedback">{cardExpiryErr}</div>}
                                    </div>
                                    <div className="col-6 mb-4">
                                        <div className="with_icon">
                                        <CardCvcElement
                                            className={`form_control form-control ${cardCvcErr ? 'is-invalid' : ''}`}
                                            onChange={e => setCardCvcErr(e?.error?.message)}
                                            options={{placeholder: 'CVV', style: cm_style}} />
                                            <IconCvv />
                                        </div>
                                        {cardCvcErr && <div className="invalid-feedback">{cardCvcErr}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Name on card"
                                    value={cardName}
                                    onChange={e => (setCardName(e.target.value), setCardNameErr(''))}
                                    className={`form-control ${cardNameErr ? 'is-invalid' : ''}`}
                                />
                                {cardNameErr && <div className="invalid-feedback">{cardNameErr}</div>}
                            </div>

                            <div className="mb-5 d-flex align-items-center">
                                <span className="cm_checkbox me-2"><input type="checkbox" /> <span /></span> I accept <Link className="" to="/"><b className="fw-bold ms-2">Terms & Conditions</b></Link>
                            </div>

                            {resMsg ? <h6 className="res_err mb-3">{resMsg}</h6> : ''}
                            
                            <button disabled={btnLoader} className="btn btn-primary w-100 py-3"><span className="me-3">Save & Continue</span> {btnLoader ? <IconSpiner /> : ''}</button>

                            <div className="mt-5 text-center">
                                Already registered? <Link to="/auth/login"><b className="fw-bold">Sign In</b></Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {
                signup_sucess ? <div className="signup_sucess">
                <div>
                    Your Log In credentials and account information have been send to<br />{email}<br />
                    <Link className="btn btn-light px-5" to="/auth/login">Ok</Link>
                </div>
            </div> : ''
            }

            
        </div>
    )
}

export default Signup;