import { useNavigate } from 'react-router-dom'
import shipDomesticImg from '../../assets/images/ship-domestic.svg'
import shipInternationalImg from '../../assets/images/ship-international.svg'


const Ship = () => {
    const navigate = useNavigate();
    const handleRoute = url => navigate(`/ship/${url}`);

    return (
        <div className="body_wrapper">
            <div className="cm_body_header">
                <h5 className='mb-0'>Ship</h5>
            </div>

            <div className="d-flex w-100 min_vh_100 justify-content-center align-items-center">
                <div className='d-flex w-100 justify-content-center align-items-center'>
                    <div onClick={() => handleRoute('domestic')} className="card me-5 w-25 p-5 text-center cursor-pointer">
                        <img style={{width: '80px', margin: '0 auto'}} src={shipDomesticImg} />
                        <h6 className='mt-4 mb-0'>Ship</h6>
                        <p className='mb-0'>(Domestic)</p>
                    </div>

                    <div onClick={() => handleRoute('international')} className="card w-25 p-5 text-center cursor-pointer">
                        <img style={{width: '80px', margin: '0 auto'}} src={shipInternationalImg} />
                        <h6 className='mt-4 mb-0'>Ship</h6>
                        <p className='mb-0'>(International)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ship;