import { useDispatch, useSelector } from 'react-redux';

import { IconCrossSmall, IconLeftArrow, IconRightArrow } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import labelPrinterImg from '../../assets/images/label-printer.png';
import receiptPrinterImg from '../../assets/images/receipt-printer.png';
import stampPrinterImg from '../../assets/images/stamp-printer.png';
import reportPrinterImg from '../../assets/images/report-printer.png';
import { useEffect } from 'react';
import { printerListService } from '../../service';


const PrinterSetup = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user);

    
    
    const handleSubmit = () => {
        dispatch(setUserProfileAction({...userProfile, tab: 4}));
    }
    
    const handleBackPage = () => {
        dispatch(setUserProfileAction({...userProfile, tab: 2}));
    }


    // useEffect(() => {
    //     printerListService().then(res => {
    //         console.log(res, '------')
    //     })
    // }, [])


    return (
        <div className='page_wrapper'>
            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">2.2</span> Printer Setup</h3>
                </div>



                <div className="container">
                    <div className="row">

                        <div className='col-lg-3 col-md-6'>
                            <div className='printer_box'>
                                <h3>Label printer</h3>
                                <img src={labelPrinterImg} alt='Electronic Scale' />
                                <select className='form-control'>
                                    <option>Select Printer</option>
                                    <option>Device 1</option>
                                    <option>Device 2</option>
                                    <option>Device 3</option>
                                    <option>Device 4</option>
                                </select>
                                <div className='text-end mt-3'>
                                    <button className='btn btn_sky px-4 btn-sm'>Test</button>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6'>
                            <div className='printer_box'>
                                <h3>Receipt Printer</h3>
                                <img src={receiptPrinterImg} alt='Electronic Scale' />
                                <select className='form-control'>
                                    <option>Select Printer</option>
                                    <option>Device 1</option>
                                    <option>Device 2</option>
                                    <option>Device 3</option>
                                    <option>Device 4</option>
                                </select>
                                <div className='text-end mt-3'>
                                    <button className='btn btn_sky px-4 btn-sm'>Test</button>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6'>
                            <div className='printer_box'>
                                <h3>Stamp Printer</h3>
                                <img src={stampPrinterImg} alt='Electronic Scale' />
                                <select className='form-control'>
                                    <option>Select Printer</option>
                                    <option>Device 1</option>
                                    <option>Device 2</option>
                                    <option>Device 3</option>
                                    <option>Device 4</option>
                                </select>
                                <div className='text-end mt-3'>
                                    <button className='btn btn_sky px-4 btn-sm'>Test</button>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6'>
                            <div className='printer_box'>
                                <h3>Report Printer</h3>
                                <img src={reportPrinterImg} alt='Electronic Scale' />
                                <select className='form-control'>
                                    <option>Select Printer</option>
                                    <option>Device 1</option>
                                    <option>Device 2</option>
                                    <option>Device 3</option>
                                    <option>Device 4</option>
                                </select>
                                <div className='text-end mt-3'>
                                    <button className='btn btn_sky px-4 btn-sm'>Test</button>
                                </div>
                            </div>
                        </div>







                        



                    </div>
                </div>


        

                <div className="d-flex footer_navigate px-5">
                    <span onClick={handleBackPage} className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></span>
                    <span className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></span>
                    <span onClick={handleSubmit} className="btn btn-dark ms-5"><span className="me-3">Next</span> <IconRightArrow /></span>
                </div>
            </div>
        </div>
        
    )
}

export default PrinterSetup;