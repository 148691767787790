import settings from "./settings";

export  const API = {
  "BASIC_AUTH": process.env.REACT_APP_BASIC_AUTH,
  "PRINTER_LIST": settings.api.url + "user/printer-list",
  "UPDATE_USER_TOKEN": settings.api.url + "user/update-token",
  "USER_SIGNUP": settings.api.url + "user/register",
  "USER_LOGIN": settings.api.url + "user/login",
  "FORGET_PASSWORD": settings.api.url + "user/forgot-password",
  "GET_USER_PROFILE": settings.api.url + "user/profile",
  "ADD_STORE": settings.api.url + "store/add",
  "GET_STORE": settings.api.url + "store/get",
  "UPLOAD_FILE": settings.api.url + "store/upload",
  "ADD_SHIPPING": settings.api.url + "ship-setting/add",
  "GET_SHIPPING": settings.api.url + "ship-setting/get",
  "ADD_DROP_SETTING": settings.api.url + "drop-setting/add",
  "GET_DROP_SETTING": settings.api.url + "drop-setting/get",
  "ADD_CARRIER_SETTING": settings.api.url + "carrier-setting/add",
  "GET_CARRIER_SETTING": settings.api.url + "carrier-setting/get",
  "ADD_INVENTORY_BY_FILE": settings.api.url + "inventory/bulk-add",
  "GET_CUSTOMER_LIST": settings.api.url + "customer/list",
  "ADD_CUSTOMER": settings.api.url + "customer/add",
}