import { IconCompany, IconDelete, IconLocation, IconPhone, IconUser } from "../SvgIcon";

const CustomerCard = ({data}) => {
    return(
        <div className="card p-3 customer_card">
            <div className="d-flex justify-content-between">
                <div style={{width: 'calc(100% - 100px)'}}>
                    <h4><IconUser /> <span className="ms-3">{data.fname} {data.lname}</span></h4>
                    <p><IconCompany /> <span className="ms-3">{data.company}</span></p>
                    <p><IconLocation /> <span className="ms-3 dots_3">{data.addressLine1} {data.addressLine2}</span></p>
                    <p><IconPhone /> <span className="ms-3">{data.phone}</span></p>
                </div>
                <div style={{minWidth: '100px'}} className="d-flex flex-column justify-content-between align-datas-end">
                    <span className="color_gray text-end"><IconDelete /></span>
                    <span className="btn btn_sky btn-sm">View Profile</span>
                </div>
            </div>
        </div>
    )
}

export default CustomerCard;