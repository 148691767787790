import React, { useState } from 'react';
import { emailRegex } from '../../helper';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import { addCustomerService } from '../../service';
import { useNavigate } from 'react-router-dom';

const CustomerInfoForm = ({ pageType }) => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        company: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        zipcode: '',
        state: '',
        country: '',
        phone: '',
        alternatePhone: '',
        email: '',
        fax: '',
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
        setErrors({...errors, [name]: ''});
    };

    const handleChange2 = (name, value) => {
        setFormData({...formData, [name]: value});
        setErrors({...errors, [name]: ''});
    };


    const handleValidate = () => {
        const newErrors = {};
        if (!formData.fname) newErrors.fname = 'First Name is required';
        if (!formData.lname) newErrors.lname = 'Last Name is required';
        if (!formData.company) newErrors.company = 'Company is required';
        if (!formData.addressLine1) newErrors.addressLine1 = 'Address line 1 is required';
        if (!formData.addressLine2) newErrors.addressLine2 = 'Address line 2 is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.zipcode) newErrors.zipcode = 'Zip code is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.country) newErrors.country = 'Country is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.alternatePhone) newErrors.alternatePhone = 'Alternate Phone number is required';
        if (!formData.fax) newErrors.fax = 'Fax number is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidate()) return;
        
        setBtnLoader(true);
        
        try {
            const params = { ...formData };
            await addCustomerService(params);
            navigate(`/ship/${pageType}`)
        } catch (serviceError) {
            console.error('Error:', serviceError);
        } finally {
            setBtnLoader(false);
        }
    };
    

    return (
        <div className="body_wrapper">
            <div className="cm_body_header">
                <h5 className='mb-0'>Ship ({pageType}) {'>'} <b>Add Customer Info</b></h5>
            </div>

            <div className="cm_min_height">
                <div className='container card p-5'>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <h5>Name & Address</h5>

                                <div className="form-group mb-3">
                                    <div className='row'>
                                        <div className='col-md-2'>
                                        <select className='form-control'>
                                            <option>Mr.</option>
                                            <option>Mrs.</option>
                                            <option>Ms.</option>
                                            <option>Dr.</option>
                                            <option>Prof.</option>
                                        </select>
                                        </div>
                                        <div className='col-md-5'>
                                            <input
                                            className={`form-control ${errors.fname ? 'is-invalid' : ''}`} 
                                            type="text"
                                            name="fname"
                                            value={formData.fname}
                                            onChange={handleChange}
                                            placeholder="First Name"
                                        />
                                        {errors.fname && <small className="text-danger">{errors.fname}</small>}
                                        </div>
                                        <div className='col-md-5'>
                                            <input
                                            className={`form-control ${errors.lname ? 'is-invalid' : ''}`} 
                                            type="text"
                                            name="lname"
                                            value={formData.lname}
                                            onChange={handleChange}
                                            placeholder="Last Name"
                                        />
                                        {errors.lname && <small className="text-danger">{errors.lname}</small>}
                                        </div>
                                    </div>
                                    
                                </div>

                    

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.company ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        placeholder="Company Name"
                                    />
                                    {errors.company && <small className="text-danger">{errors.company}</small>}
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.addressLine1 ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="addressLine1"
                                        value={formData.addressLine1}
                                        onChange={handleChange}
                                        placeholder="Address Line 1"
                                    />
                                    {errors.addressLine1 && <small className="text-danger">{errors.addressLine1}</small>}
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.addressLine2 ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="addressLine2"
                                        value={formData.addressLine2}
                                        onChange={handleChange}
                                        placeholder="Address Line 2"
                                    />
                                    {errors.addressLine2 && <small className="text-danger">{errors.addressLine2}</small>}
                                </div>

                                <div className="form-group mb-3">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <input
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`} 
                                                type="text"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                placeholder="City"
                                            />
                                            {errors.city && <small className="text-danger">{errors.city}</small>}
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} 
                                                type="text"
                                                name="zipcode"
                                                value={formData.zipcode}
                                                onChange={handleChange}
                                                placeholder="Zip Code"
                                            />
                                            {errors.zipcode && <small className="text-danger">{errors.zipcode}</small>}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.state ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        placeholder="State"
                                    />
                                    {errors.state && <small className="text-danger">{errors.state}</small>}
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.country ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        placeholder="Country"
                                    />
                                    {errors.country && <small className="text-danger">{errors.country}</small>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h5>Contact Information</h5>


                                <div className="form-group mb-3">
                                    <CustomPhoneInput
                                        placeholder="Phone Number"
                                        val={formData.phone}
                                        err={errors.phone}
                                        handlePhoneChange={e => handleChange2('phone', e.target.value)} />
                                </div>

                                <div className="form-group mb-3">
                                    <CustomPhoneInput
                                        placeholder="Alternate Phone Number"
                                        val={formData.alternatePhone}
                                        err={errors.alternatePhone}
                                        handlePhoneChange={e => handleChange2('alternatePhone', e.target.value)} />
                    
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        className={`form-control ${errors.fax ? 'is-invalid' : ''}`} 
                                        type="text"
                                        name="fax"
                                        value={formData.fax}
                                        onChange={handleChange}
                                        placeholder="Fax"
                                    />
                                    {errors.fax && <small className="text-danger">{errors.fax}</small>}
                                </div>

                                <h5>Identification</h5>

                
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfoForm;
