import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import BrandLogo from '../../assets/images/brand-logo.png'
import { useEffect, useState } from "react";
import { forgetPasswordService } from "../../service";
import { setUserProfileAction } from "../../store/userSlice";
import { emailRegex } from "../../helper";

const ForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logoLoader, setLogoLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [signup_sucess, setSignup_sucess] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setLogoLoader(false)
        }, 1500)
    }, [])



    const handleValidate = () => {
        let validate = true;

        if (!email.trim()) {
          setEmailErr('Email is required');
          validate = false;
        } else if (!emailRegex.test(email)) {
          setEmailErr('Invalid email format');
          validate = false;
        }

        return validate;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResMsg('');
    
        if (handleValidate()) {
            setBtnLoader(true);
            const params = { email };
            try {
                const res = await forgetPasswordService(params);
                setSignup_sucess(true);
            } catch (serviceError) {
                setResMsg(serviceError?.response?.data?.error || 'An error occurred during forget password.');
            } finally {
                setBtnLoader(false);
            }
        }
    };


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if(token){
            navigate('/');
        }
    }, [])
    

    return (
        <div className="min-vh-100 d-flex bg-white align-items-center">
            <div className={`auth_logo_wrapper`}>
               <div>
                    <img src={BrandLogo} alt="Postalpros" />
                    <div className="dots_animate">
                        <span className="dot_1" />
                        <span className="dot_2" />
                        <span className="dot_3" />
                        <span className="dot_4" />
                    </div>
               </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-5">
                        <form className="p-5" onSubmit={handleSubmit}>
                            <div className='cm_hidden'>
                                <input type='email' name="email" id="email" />
                                <input type='password' name="password" id="password"  />
                            </div>
                            <h2 className="mb-4 fw-bolder">Forget Password ?</h2>

                            <div className="mb-4">
                                <input
                                    type='email'
                                    value={email}
                                    onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                    className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                    placeholder='Enter Email' />

                                {emailErr && <span className='invalid-feedback'>{emailErr}</span>}
                            </div>


                            <div className="d-flex justify-content-end mb-5">
                                <Link to="/auth/login">Sign In</Link>
                            </div>

                            {resMsg ? <h6 className="res_err mb-3">{resMsg}</h6> : ''}
                            <button
                                type="submit" 
                                disabled={btnLoader}
                                className="btn btn-primary w-100 py-3"> {btnLoader ? 'Loading...' : 'Submit'}</button>

                            <div className="mt-5 text-center">
                                Don’t have an account?<Link to="/auth/signup"> Register Now</Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {
                signup_sucess ? <div className="signup_sucess">
                <div>
                    Your Log In credentials and account information have been send to<br />{email}<br />
                    <Link className="btn btn-light px-5" to="/auth/login">Ok</Link>
                </div>
            </div> : ''
            }
        </div>
    )
}

export default ForgetPassword;