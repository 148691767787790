import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import settings from './config/settings';

const root = ReactDOM.createRoot(document.getElementById('root'));

const stripePromise = loadStripe(settings.api.stripeToken);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
  </Provider>
  </React.StrictMode>
);

reportWebVitals();
