import { useState } from "react";
import { Modal } from "react-bootstrap";

const FedExWholesaleRatesModal = ({show, calback, calbackTier}) => {
    const [tier, setTier] = useState('');


    return(
        <Modal
            show={show}
            size="lg"
            onHide={calback}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="px-5">
                <h3>Set FedEx Express Wholesale Rates</h3>
                <p>Select your location's FASC Express Tier, then press 'Apply' to set your wholesale shipping rates.</p>

                <div className='card p-3' style={{minHeight: '300px'}}>
                    <h5>Select your tier</h5>
                    <select value={tier} onChange={e => setTier(e.target.value)} className="form-control">
                        <option value={''}>Use Published Rates</option>
                        <option value={1}>Tier 1</option>
                        <option value={2}>Tier 2</option>
                        <option value={3}>Tier 3</option>
                        <option value={4}>Tier 4</option>
                        <option value={5}>Tier 5</option>
                    </select>
                </div>

                <div className="d-flex justify-content-end mt-4 mb-4">
                    <div className="btn_actions">
                        <span className="btn btn-outline-dark px-4 btn-sm me-3" onClick={calback}>Cancel</span>
                        <span className="btn btn_sky px-4 btn-sm" onClick={() => calbackTier(tier)}>Apply</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FedExWholesaleRatesModal;