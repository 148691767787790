import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import brandLogo from '../../assets/images/brand-logo.png'
import { getUserProfileService } from '../../service';
import { setUserProfileAction } from '../../store/userSlice';


const Header = () => {
    const dispatch = useDispatch();

    const getUserProfile = async () => {
        try {
            const res = await getUserProfileService();
            localStorage.setItem('userProfile', JSON.stringify(res.data));
            dispatch(setUserProfileAction(res.data))
        } catch (error) {
            console.error('Error fetching store:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if(token){
            getUserProfile();
        }
    }, [])

    return(
        <header className='px-5'>
            <div className='main_logo'>
                <img src={brandLogo} />
            </div>
            <div className='ms-auto'>
                <DigitalClock />
            </div>
        </header>
    )
}

export default Header;





const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDate = (date) => {
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
    return (
        <p className='m-0 lato'><date>{`${dayOfWeek}, ${dayOfMonth} ${month} ${year}`}</date><time className='ms-3'>{`${hours}:${formattedMinutes} ${ampm}`}</time></p>
     
    );
};

const DigitalClock = () => {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            {formatDate(time)}
        </div>
    );
};
