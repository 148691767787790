import { useDispatch, useSelector } from 'react-redux';
import { IconCrossSmall, IconLeftArrow, IconRightArrow } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import { useEffect, useState } from 'react';

import img2DScanner from '../../assets/images/2D-scanner.png';
import imgCCProcessor from '../../assets/images/CC-processor.png';
import imgCustomerFacingMonitor from '../../assets/images/customer-facing-monitor.png';
import imgElectronicScale from '../../assets/images/electronic-scale.png';
import imgSignaturePad from '../../assets/images/signature-pad.png';

const equipmentItems = [
  { name: 'Electronic Scale', imgSrc: imgElectronicScale },
  { name: 'Signature Pad', imgSrc: imgSignaturePad },
  { name: '2D Scanner', imgSrc: img2DScanner },
  { name: 'CC Processor', imgSrc: imgCCProcessor },
  { name: 'Customer Facing Monitor', imgSrc: imgCustomerFacingMonitor },
];

const EquipmentSetup = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user);
  const [printers, setPrinters] = useState([]);

  const handleSubmit = () => {
    dispatch(setUserProfileAction({ ...userProfile, tab: 3 }));
  };

  const handleBackPage = () => {
    dispatch(setUserProfileAction({ ...userProfile, tab: 1 }));
  };

  const listPrinters = async () => {
    try {
      const devices = await navigator.usb.getDevices();
      setPrinters(devices);
    } catch (error) {
      console.error('Error listing printers:', error);
    }
  };

  useEffect(() => {
    listPrinters();
  }, []);

  return (
    <div className='page_wrapper'>
      <div className="page_wrap">
        <div className="container-fluid px-5 pt-5">
          <h2 className="mb-5">Configure your store setup</h2>
          <h3 className="mb-5"><span className="tab_number">2.1</span> Equipment Setup</h3>
        </div>

        <div className="container-fluid container">
          <div className="row">
            {equipmentItems.map((item, index) => (
              <div key={index} className='col-md-6 col-lg-4'>
                <div className='equipment_box'>
                  <div className='equipment_img'>
                    <img src={item.imgSrc} alt={item.name} />
                  </div>
                  <div className='equipment_content'>
                    <h3>{item.name}</h3>
                    <select className='form-control'>
                      <option>Select Device</option>
                      {printers.map((printer, idx) => (
                        <option key={idx}>{printer?.productName}</option>
                      ))}
                    </select>
                    <div className='text-end mt-3'>
                      <button className='btn btn_sky px-4 btn-sm'>Test</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex footer_navigate px-5">
          <button onClick={handleBackPage} className="btn btn-outline-dark">
            <IconLeftArrow />
            <span className="ms-3">Back</span>
          </button>
          <button className="btn btn-outline-dark ms-auto">
            <span className="me-3">Cancel</span>
            <IconCrossSmall />
          </button>
          <button onClick={handleSubmit} className="btn btn-dark ms-5">
            <span className="me-3">Next</span>
            <IconRightArrow />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EquipmentSetup;
