import { NavLink } from "react-router-dom";
import { IconDropOff, IconHelp, IconLogout, IconMailboxes, IconOthers, IconPacktastic, IconSettings, IconShip } from "../SvgIcon";


const Sidebar = () => {


    const primaryMenu = [
        {label: 'Ship', icon: <IconShip />, url: '/ship'},
        {label: 'Drop Off', icon: <IconDropOff />, url: '/drop-off'},
        {label: 'Packtastic', icon: <IconPacktastic />, url: '/packtastic'},
        {label: 'Postage', icon: <IconPacktastic />, url: '/postage'},
        {label: 'Mailboxes', icon: <IconMailboxes />, url: '/mail-boxes'},
        {label: 'Others', icon: <IconOthers />, url: '/others'},
        {label: 'POS / Reporting', icon: <IconPacktastic />, url: '/pos-reporting'},
    ]

    const secondaryMenu = [
        {label: 'Help', icon: <IconHelp />, url: '/help'},
        {label: 'Settings', icon: <IconSettings />, url: '/settings'},
    ]

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/auth/login';
    }
    

    return(
        <div className='cm_sidebar'>
             <ul className="primary_menu">
                {primaryMenu.map((item, i) => (
                    <li key={i}>
                        <NavLink to={item.url} activeClassName='active'>
                            {item.icon}
                            <span>{item.label}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
            <ul className="secondary_menu">
                {secondaryMenu.map((item, i) => (
                    <li key={i}>
                        <NavLink to={item.url} activeClassName='active'>
                            {item.icon}
                            <span>{item.label}</span>
                        </NavLink>
                    </li>
                ))}

                <li>
                    <div className="cm_logout" onClick={handleLogout}>
                        <IconLogout />
                        <span>Log Out</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar;


