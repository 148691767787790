import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';

import Login from './pages/auth/Login';
import Home from './pages/home/Home';
import Signup from "./pages/auth/Signup";
import Onboarding from "./pages/onboarding";
import Header from "./components/layout/Header";
import ForgetPassword from "./pages/auth/ForgetPassword";
import { Fragment } from "react";
import Sidebar from "./components/layout/Sidebar";
import Ship from "./pages/ship/Ship";
import AllCustomerDetails from "./pages/ship/AllCustomerDetails";
import CustomerInfoForm from "./pages/ship/CustomerInfoForm";



const PrivateRoute = () => {
  const isAuthenticated = Boolean(localStorage.getItem("accessToken"));
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
};

const App = () => {
  const userProfile = useSelector((state) => state.user);

  return (
    <div className="app">
      <BrowserRouter>
        {userProfile._id && <Fragment>
          <Header />
          {userProfile.isProfileComplete && <Sidebar />}
          </Fragment>}
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/auth/forget-password" element={<ForgetPassword />} />
          <Route path="/" element={<PrivateRoute />}><Route index element={<Ship />} /></Route>
          <Route path="/ship" element={<PrivateRoute />}><Route index element={<Ship />} /></Route>
          <Route path="/ship/domestic" element={<PrivateRoute />}><Route index element={<AllCustomerDetails pageType="domestic" />} /></Route>
          <Route path="/ship/domestic/add-customer-info" element={<PrivateRoute />}><Route index element={<CustomerInfoForm pageType="domestic" />} /></Route>
          <Route path="/ship/international" element={<PrivateRoute />}><Route index element={<AllCustomerDetails pageType="international" />} /></Route>
          <Route path="/ship/international/add-customer-info" element={<PrivateRoute />}><Route index element={<CustomerInfoForm pageType="international" />} /></Route>
          <Route path="/onboarding" element={<PrivateRoute />}><Route index element={<Onboarding />} /></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;