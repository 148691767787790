import { useDispatch, useSelector } from 'react-redux';

import { IconCrossSmall, IconLeftArrow, IconRightArrow, IconSpiner } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import { Fragment, useEffect, useState } from 'react';
import { addDropSettingService } from '../../service';




const DropOffSetup = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user);
    const dropOff = useSelector((state) => state.myStore.dropOff);
    const [screen, setScreen] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [hasChanged, setHasChanged] = useState(false);

    const [carrier, setCarrier] = useState('');
    const [service, setService] = useState('');
    const [mode, setMode] = useState('');
    const [view, setView] = useState('');
    const [carrierErr, setCarrierErr] = useState('');
    const [serviceErr, setServiceErr] = useState('');
    const [modeErr, setModeErr] = useState('');
    const [viewErr, setViewErr] = useState('');

    const [rates, setRates] = useState(initialRate || []);

    const [carrierSpecificSettings, setCarrierSpecificSettings] = useState({
        services: '',
        compensation: '',
        retailCharges: '',
        department: ''
    });
    const [addOns, setAddOns] = useState(addOnsList || []);
    const [insurance, setInsurance] = useState('');
    const [receipt, setReceipt] = useState({
        storeDisclaimer: '',
        receiptPrinter: false,
        copies: 1
    });


    useEffect(() => {
        if(dropOff._id){
            setReceipt(dropOff.receipt);
            setInsurance(dropOff.insurance);
            setAddOns(dropOff.addOns);
            setCarrierSpecificSettings(dropOff.carrierSpecificSettings);
            setCarrier(dropOff.weightBasedRates.carrier);
            setService(dropOff.weightBasedRates.service);
            setMode(dropOff.weightBasedRates.mode);
            setView(dropOff.weightBasedRates.view);
            setRates(dropOff.weightBasedRates.rates);
            setScreen(2);
        }
    }, [dropOff])



    const handleInputChange = (e, index, day) => {
        setHasChanged(true)
        const { value } = e.target;
        const newRates = [...rates];
        newRates[index].days[day] = value;
        setRates(newRates);
    };


    
    const handleBackPage = () => {
        if(screen == 2){
            setScreen(1)
        }else{
            dispatch(setUserProfileAction({...userProfile, tab: 5}));
        }
    }




    const handleValidate1 = () => {
        let validate = true;

        if(!carrier){
            validate = false;
            setCarrierErr('Please Select Carrier')
        }

        if(!service){
            validate = false;
            setServiceErr('Please Select Service')
        }

        if(!mode){
            validate = false;
            setModeErr('Please Select Mode')
        }

        if(!view){
            validate = false;
            setViewErr('Please Select View')
        }

        return validate
    }


    const handleApply = () => {
        if(handleValidate1()){
            setScreen(2)
        }
    }


    const handleCheckboxChange = (index) => {
        setHasChanged(true)
        const newAddOns = [...addOns];
        newAddOns[index].enabled = !newAddOns[index].enabled;
        setAddOns(newAddOns);
    };



    const handleValidate2 = () => {
        let validate = true;


        return validate
    }




    const handleSubmit = async () => {
        if (handleValidate2()) {
            setBtnLoader(true);
            try {

                const params = {
                    weightBasedRates: {
                        carrier,
                        service,
                        mode,
                        view,
                        rates
                    },
                    carrierSpecificSettings,
                    addOns,
                    insurance,
                    receipt
                };

                if(hasChanged){
                    const res = await addDropSettingService(params);
                    dispatch(setUserProfileAction({ ...userProfile, tab: 7 }));  
                }else{
                    dispatch(setUserProfileAction({ ...userProfile, tab: 7 }));  
                }
 
                } catch (serviceError) {
                setResMsg(serviceError?.response?.data?.error);
            }

            setBtnLoader(false);
        }
    };


    return (
        <div className='page_wrapper'>
            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">5</span> Drop Off Setup</h3>
                </div>


                {
                    screen == 1 ? (
                        <Fragment>
                            <div className='container card p-4'>
                                <div className=''>
                                    <h5>View Weight based rates</h5>

                                    <div className='d-flex justify-content-between align-items-top mt-4'>
                                        <div className='form-group'>
                                            <select
                                                value={carrier}
                                                onChange={e => (setCarrier(e.target.value), setCarrierErr(''), setHasChanged(true))}
                                                style={{minWidth: '207px'}}
                                                className={`form-control ${carrierErr ? 'is-invalid' : ''}`}>
                                                <option>FedEx Ground</option>
                                                <option>FedEx Ground</option>
                                                <option>FedEx Ground</option>
                                            </select>
                                            {carrierErr && <span className='invalid-feedback'>{carrierErr}</span>}
                                        </div>

                                        <div className='form-group'>
                                            <select
                                                value={service}
                                                onChange={e => (setService(e.target.value), setServiceErr(''), setHasChanged(true))}
                                                style={{minWidth: '207px'}}
                                                className={`form-control ${serviceErr ? 'is-invalid' : ''}`}>
                                                <option>FedEx Ground</option>
                                                <option>FedEx Ground</option>
                                                <option>FedEx Ground</option>
                                            </select>
                                            {serviceErr && <span className='invalid-feedback'>{serviceErr}</span>}
                                        </div>

                                        <div className='form-group'>
                                            <b className='me-3'>Mode</b>
                                            <select
                                                value={mode}
                                                onChange={e => (setMode(e.target.value), setModeErr(''), setHasChanged(true))}
                                                style={{minWidth: '120px'}}
                                                className={`form-control d-inline-block w-auto ${modeErr ? 'is-invalid' : ''}`}>
                                                <option>View</option>
                                                <option>View</option>
                                                <option>View</option>
                                            </select>
                                            {modeErr && <span className='invalid-feedback ms-5 ps-2'>{modeErr}</span>}
                                        </div>

                                        <div className='form-group'>
                                            <b className='me-3'>View</b>
                                            <select
                                                value={view}
                                                onChange={e => (setView(e.target.value), setViewErr(''), setHasChanged(true))}
                                                style={{minWidth: '272px'}}
                                                className={`form-control d-inline-block w-auto ${viewErr ? 'is-invalid' : ''}`}>
                                                <option>Variable Pricing</option>
                                                <option>Variable Pricing</option>
                                                <option>Variable Pricing</option>
                                            </select>
                                            {viewErr && <span className='invalid-feedback ms-5 ps-2'>{viewErr}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className='card p-3 mt-3'>
                                {/* <div className='mb-4'>
                                    <p className='d-inline-block mb-0 text_light fwb'>From</p>
                                        <input
                                            style={{ maxWidth: '150px' }}
                                            type='date'
                                            className='form-control d-inline-block w-auto ms-3' />

                                        <p className='d-inline-block mb-0 ms-3 text_light fwb'>Till</p>
                                        <input
                                            style={{ maxWidth: '150px' }}
                                            type='date'
                                            className='form-control d-inline-block w-auto ms-3'/>
                                </div> */}

                                <div className="dor_wrapper">

                                    <table className="table border-0 text-center">
                                        <thead>
                                            <tr className="border-top-0">
                                                <th>Day</th>
                                                <th className="border_left">Mon</th>
                                                <th className="border_left">Tue</th>
                                                <th className="border_left">Wed</th>
                                                <th className="border_left">Thu</th>
                                                <th className="border_left">Fri</th>
                                                <th className="border_left">Sat</th>
                                                <th className="border_left">Sun</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                rates.map((item, index) => (
                                                    <tr>
                                                        <td className={`dpb_time ${rates.length == (index + 1) ? 'border-bottom-0' : ''}`}>{item.from} - {item.to}</td>
                                                        {
                                                            Object.keys(item.days).map((day, dayIndex) => (
                                                                <td className={`dpb_day border_left ${rates.length == (index + 1) ? 'border-bottom-0' : ''}`} key={dayIndex}>
                                                                    <input
                                                                        style={{ maxWidth: '90px', textAlign: 'center' }}
                                                                        type='number'
                                                                        className='form-control border-0 w-auto mx-auto'
                                                                        value={item.days[day]}
                                                                        min="0"
                                                                        max="100"
                                                                        onChange={(e) => handleInputChange(e, index, day)}  />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>

                                


                                </div>
                            </div>


                            <div className='container mt-3'>
                                <p><b>Direct editing:</b> Click on a cell and type in the desired rate.</p>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className='container card p-4'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h5>Carrier specific settings</h5>
                                        <div className='card p-3 py-4 mt-3 mb-4'>
                                            <div className='row align-items-center mb-3'>
                                                <div className='col-3'><p className='mb-0'>Services</p></div>
                                                <div className='col-9'>
                                                    <select
                                                        value={carrierSpecificSettings.services}
                                                        onChange={e => (setCarrierSpecificSettings({...carrierSpecificSettings, services: e.target.value}), setHasChanged(true))}
                                                        style={{minWidth: '270px'}}
                                                        className='form-control d-inline-block w-auto'>
                                                        <option>None</option>
                                                        <option>Service 1</option>
                                                        <option>Service 2</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='row align-items-center mb-3'>
                                                <div className='col-3'><p className='mb-0'>Compensation</p></div>
                                                <div className='col-9'>
                                                    <input
                                                        value={carrierSpecificSettings.compensation}
                                                        onChange={e => (setCarrierSpecificSettings({...carrierSpecificSettings, compensation: e.target.value}), setHasChanged(true))}
                                                        style={{ maxWidth: '150px' }}
                                                        placeholder='0.00'
                                                        type='number'
                                                        className='form-control d-inline-block w-auto' />
                                                    <p className='d-inline-block mb-0 ms-3'>(Carrier pays store)</p>
                                                </div>
                                            </div>

                                            <div className='row align-items-center mb-3'>
                                                <div className='col-3'><p className='mb-0'>Retail Charges</p></div>
                                                <div className='col-9'>
                                                    <input
                                                        value={carrierSpecificSettings.retailCharges}
                                                        onChange={e => (setCarrierSpecificSettings({...carrierSpecificSettings, retailCharges: e.target.value}), setHasChanged(true))}
                                                        style={{ maxWidth: '150px' }}
                                                        placeholder='0.00'
                                                        type='number'
                                                        className='form-control d-inline-block w-auto' />
                                                </div>
                                            </div>


                                            <div className='row align-items-center'>
                                                <div className='col-3'><p className='mb-0'>Department</p></div>
                                                <div className='col-9'>
                                                    <select
                                                        value={carrierSpecificSettings.department}
                                                        onChange={e => (setCarrierSpecificSettings({...carrierSpecificSettings, department: e.target.value}), setHasChanged(true))}
                                                        style={{minWidth: '270px'}}
                                                        className='form-control d-inline-block w-auto'>
                                                        <option>Select</option>
                                                        <option>Service 1</option>
                                                        <option>Service 2</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <h5 className='mb-4'>Add-Ons & Insurance</h5>
                                        <div className="iao_wrapper">
                                            <div className="iao_row iao_head">
                                                <div className="iao_col">Description</div>
                                                <div className="iao_col text-center">Enabled</div>
                                                <div className="iao_col text-center">Retail</div>
                                                <div className="iao_col text-center">More details</div>
                                            </div>

                                            <div className="py-3">
                                                {
                                                    addOns.map((item, i) => (
                                                        <div className="iao_row" key={i}>
                                                            <div className="iao_col">{item.description}</div>
                                                            <div className="iao_col text-center">
                                                                <span className="cm_checkbox me-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item.enabled}
                                                                        onChange={() => handleCheckboxChange(i)} />
                                                                    <span />
                                                                </span>
                                                            </div>
                                                            <div className="iao_col text-center">{item.retail}</div>
                                                            <div className="iao_col text-center">{item.details}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>



                                        <div className='row align-items-center mt-4 mb-4'>
                                            <div className='col-3'><p className='mb-0'>Insurance</p></div>
                                            <div className='col-9'>
                                                <select
                                                    value={insurance}
                                                    onChange={e => (setInsurance(e.target.value), setHasChanged(true))}
                                                    style={{minWidth: '270px'}}
                                                    className='form-control d-inline-block w-auto'>
                                                    <option>None</option>
                                                    <option>insurance 1</option>
                                                    <option>insurance 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <h5>Receipt</h5>
                                        <div className='card p-3 mt-3'>
                                            <h6>Store Disclaimer </h6>
                                            <textarea
                                                defaultValue={'I, the undersigned, certify that the Retailer identified below ("Retailer") has provided me a list of items/substances that are considered potentially Hazardous. I, the undersigned, certify that the Retailer identified below ("Retailer") has provided me a list of items/substances that are considered potentially Hazardous. I, the undersigned, certify that the Retailer identified below ("Retailer") has provided me a list of items/substances that are considered potentially Hazardous.'}
                                                className='form-control'
                                                value={receipt.storeDisclaimer}
                                                onChange={e => (setReceipt({...receipt, storeDisclaimer: e.target.value}), setHasChanged(true))}
                                                rows={10} />

                                            <div className='d-flex align-items-center mt-4'>
                                                <span className='cm_radio'>
                                                    <input
                                                        name='receiptPrinter'
                                                        checked={receipt.receiptPrinter}
                                                        onChange={e => (setReceipt({...receipt, receiptPrinter: true}), setHasChanged(true))}
                                                        type='radio' />
                                                    <span />
                                                </span>
                                                <p className='mb-0 ms-2'>Receipt Printer</p>

                                                <span className='cm_radio ms-5'>
                                                    <input
                                                        name="receiptPrinter"
                                                        checked={receipt.receiptPrinter}
                                                        onChange={e => (setReceipt({...receipt, receiptPrinter: false}), setHasChanged(true))}
                                                        type='radio' />
                                                    <span />
                                                </span>
                                                <p className='mb-0 ms-2'>Receipt Printer</p>
                                            </div>


                                            <div className='row align-items-center mb-3 mt-4'>
                                                <div className='col-2'><p className='mb-0'>Copies</p></div>
                                                <div className='col-3'>
                                                    <select
                                                        value={receipt.copies}
                                                        onChange={e => (setReceipt({...receipt, copies: e.target.value}), setHasChanged(true))}
                                                        style={{minWidth: '85px'}}
                                                        className='form-control d-inline-block w-auto'>
                                                        <option value={1} >1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                    </select>
                                                </div>
                                                <div className='col-1'></div>

                                                <div className='col-6'>
                                                    <select
                                                        className='form-control'>
                                                        <option>Select Printer</option>
                                                        <option>Printer 1</option>
                                                        <option>Printer 2</option>
                                                        <option>Printer 3</option>
                                                        <option>Printer 4</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div className='text-end'>
                                                <span className='btn btn_sky px-4 btn-sm'>Test</span>
                                            </div>
                                
                                        </div>
                                    </div>

                                    <div className='col-12 text-center'>
                                        {resMsg ? <h6 className="res_err mb-3">{resMsg}</h6> : ''}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }



                


        

                <div className="d-flex footer_navigate px-5">
                    <span onClick={handleBackPage} className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></span>
                    <span className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></span>
                    {screen == 1 ? <span onClick={handleApply} className="btn btn-dark ms-5">Apply</span> : <button onClick={() => btnLoader ? null : handleSubmit()} className="btn btn-dark ms-5"><span className="me-3">Next</span> {btnLoader ? <IconSpiner /> : <IconRightArrow />}</button>}
                    
                </div>
            </div>
        </div>
        
    )
}

export default DropOffSetup;

const addOnsList = [
    {
        description: 'Email Notification',
        retail: 12,
        details: 'XXXXX',
        enabled: false
    },
    {
        description: 'Taping & Sealing',
        retail: 12,
        details: 'XXXXX',
        enabled: false
    },
]


const initialRate = [
    {
        from: '12 am',
        to: '3 am',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '3 am',
        to: '6 am',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '6 am',
        to: '9 am',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '9 am',
        to: '13 pm',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '12 pm',
        to: '3 pm',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '3 pm',
        to: '6 pm',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '6 pm',
        to: '9 pm',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
    {
        from: '9 pm',
        to: '12 am',
        days: {mon: '45', tue: '45', wed: '45', thu: '45', fri: '45', sat: '45', sun: '45'}
    },
]