import { useEffect, useState } from "react";

const SelectServices = ({ _carriers, onServicesChange }) => {

    const [carriers, setCarriers] = useState([]);
    const [activeService, setActiveService] = useState('FedEx');


    useEffect(() => {
        if(_carriers.length){
            setCarriers(_carriers)
        }else{
            setCarriers(servicesList)
        }
    }, [_carriers])


    const handleCheckboxChange = (e, itemIndex, serviceIndex) => {
       let  _carriers = [...carriers];
       _carriers[itemIndex].services[serviceIndex].selected = !_carriers[itemIndex].services[serviceIndex].selected;
       setCarriers(_carriers);
       onServicesChange(_carriers);

    }

    return (
        <div className="select_services w-100 ps-3">
            <p className='mb-0 text_light'>Check service(s) to apply the above to:</p>
            
            <div className="service_tab">
                {carriers.map(item => (
                    <div  key={item} onClick={() => setActiveService(item.name)} className={item.name == activeService ? 'active' : ''}>
                        {item.name}
                    </div>
                ))}
            </div>

            {
                carriers.map((item, itemIndex) => (item.name == activeService &&
                    <div className="service_list">
                        {item.services.map((service, serviceIndex) => (
                            <div key={service.name} className="d-flex align-items-center mb-2">
                                <span className="cm_checkbox me-2">
                                    <input
                                        checked={service.selected}
                                        type="checkbox" 
                                        onChange={e => handleCheckboxChange(e, itemIndex, serviceIndex)}/> 
                                    <span />
                                </span> 
                                {service.name}
                            </div>
                        ))}
                    </div>
                ))
            }

            
        </div>
    );
};

export default SelectServices;


const servicesList = [
    {
        name: 'FedEx',
        services: [
            {name: 'FedEx Express', selected: false},
            {name: 'FedEx Ground', selected: false},
        ],
    },
    {
        name: 'UPS',
        services: [
            {name: 'UPS Air', selected: false},
            {name: 'UPS Ground', selected: false},
        ],
    },
    {
        name: 'USPS',
        services: [
            {name: 'USPS Priority', selected: false},
            {name: 'USPS First Class', selected: false},
        ],
    },
    {
        name: 'DHL',
        services: [
            {name: 'DHL Express', selected: false},
            {name: 'DHL Parcel', selected: false},
        ],
    },
];
