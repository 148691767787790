import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { IconCrossSmall, IconLeftArrow, IconRightArrow, IconRightArrowLong, IconSpiner } from "../../components/SvgIcon";
import { setUserProfileAction } from '../../store/userSlice';
import DynamicPricingFrom from '../../components/common/DynamicPricingFrom';
import SelectServices from '../../components/common/SelectServices';
import { useEffect, useState } from 'react';
import { addShippingService } from '../../service';


const ShippingSetup = () => {
    const dispatch = useDispatch();
    const shippingFromStore = useSelector((state) => state.myStore.shipping);
    const userProfile = useSelector((state) => state.user);
    const [hasDynamicPricing, setHasDynamicPricing] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);


    const [base_rate, setBase_rate] = useState('');
    const [margin, setMargin] = useState('');
    const [use_carrier_rates, setUse_carrier_rates] = useState(false);
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [time_slots, setTime_slots] = useState([]);

    const [base_rateErr, setBase_rateErr] = useState('');
    const [marginErr, setMarginErr] = useState('');
    const [carriers, setCarriers] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);



    useEffect(() => {
        if (shippingFromStore._id) {
            setCarriers(shippingFromStore.carriers || []);
            setMargin(shippingFromStore.global_rate_setting.margin.value || '');
            setBase_rate(shippingFromStore.global_rate_setting.base_rate || '');
            setUse_carrier_rates(shippingFromStore.global_rate_setting.use_carrier_rates);
            setTime_slots(shippingFromStore.global_rate_setting.time_slots || []);
            setMin(shippingFromStore.global_rate_setting.dynamic_pricing.min || []);
            setMax(shippingFromStore.global_rate_setting.dynamic_pricing.max || []);

            if (shippingFromStore.global_rate_setting.time_slots) {
                setHasDynamicPricing(true);
            }else{
                setHasDynamicPricing(false);
            }
        }
    }, [shippingFromStore]);

    
    const handleValidate = () => {
        let validate = true;

        if(!base_rate){
            setBase_rateErr('Please select base rate');
            validate = false
        }

        if(!margin){
            setMarginErr('Markup is requierd');
            validate = false
        }

        return validate;
    }



    
    const handleSubmit = async () => {
        if (handleValidate()) {
            setBtnLoader(true);
            const params = {
                global_rate_setting: {
                    base_rate,
                    margin: {
                        value: margin,
                        isPercentage: true
                    },
                    use_carrier_rates,
                    dynamic_pricing: {
                        enabled: hasDynamicPricing,
                        min: parseInt(min) || 0,
                        max: parseInt(max) || 0
                    },
                    time_slots
                },
                carriers
            };
    
            try {

                if(hasChanged){
                    await addShippingService(params);
                    dispatch(setUserProfileAction({ ...userProfile, tab: 6 }));
                }else{
                    dispatch(setUserProfileAction({ ...userProfile, tab: 6 }));
                }
            } catch (error) {
                console.error('Error adding shipping service:', error);
            }

            setBtnLoader(false);
        }
    };
    
    
    const handleBackPage = () => {
        dispatch(setUserProfileAction({...userProfile, tab: 4}));
    }


    return (
        <div className='page_wrapper'>
            <div className="page_wrap">
                <div className="container-fluid px-5 pt-5">
                    <h2 className="mb-5">Configure your store setup</h2>
                    <h3 className="mb-5"><span className="tab_number">4</span> Shipping Setup</h3>
                </div>


                <div className='container card p-4'>
                    <div className='d-flex justify-content-between'>
                        <h5>Set Retail-Shipping Rates</h5>
                        <Select
                            placeholder="Advanced"
                            className='cm_select'
                            classNamePrefix="cm_select_no_border"
                            options={[
                                { value: 'Weight-based services', label: 'Weight-based services' },
                                { value: 'Fixed-rate services', label: 'Fixed-rate services' },
                                { value: 'Cubic-rate services', label: 'Cubic-rate services' },
                              ]}/>
                    </div>

                    <div className='card p-3 mt-3'>
                        <h6>Global rate-setting</h6>

                        <div className='d-flex align-items-baseline mb-3'>
                            <div className='col-1 font-weight-bold'><span className='me-2'>Step 1</span> <IconRightArrowLong /></div>
                            <div className='ps-3'>
                                <p className='d-inline-block mb-0 text_light'>Base retail rates on</p>
                                <select
                                    value={base_rate}
                                    onChange={e => (setBase_rate(e.target.value), setBase_rateErr(''), setHasChanged(true))}
                                    style={{minWidth: '250px'}}
                                    className={`form-control d-inline-block w-auto ms-3 ${base_rateErr ? 'is-invalid' : ''}`}>
                                    <option>Select Rate Type</option>
                                    <option value="Published Rates">Published Rates</option>
                                    <option value="Wholesale Rates">Wholesale Rates</option>
                                </select>

                                {base_rateErr && <span className='invalid_feedback ms-3'>{base_rateErr}</span>}
                            </div>
                        </div>


                        <div className='d-flex align-items-baseline'>
                            <div className='col-1 font-weight-bold'><span className='me-2'>Step 2</span> <IconRightArrowLong /></div>
                            <div className='w-100 ps-3'>
                                <p className='d-inline-block mb-0 text_light'>Add a</p>
                                <input
                                    value={margin}
                                    onChange={e => (setMargin(e.target.value), setMarginErr(''), setHasChanged(true))}
                                    style={{maxWidth: '100px'}}
                                    type='text'
                                    className={`form-control d-inline-block w-auto ms-3 ${marginErr ? 'is-invalid' : ''}`} /> 
                                <p className='d-inline-block mb-0 ms-3 text_light'>% markup</p>
                                {/* <input
                                    style={{maxWidth: '100px'}}
                                    type='text'
                                    className='form-control d-inline-block w-auto ms-3' /> 
                                <p className='d-inline-block mb-0 ms-3 text_light'>% or markup </p> */}
                                {marginErr && <span className='invalid_feedback ms-3'>{marginErr}</span>}

                                <div className="mt-3 d-flex align-items-center text_light">
                                    <span className="cm_checkbox me-2"><input type="checkbox" checked={use_carrier_rates} onChange={() => (setUse_carrier_rates(!use_carrier_rates), setHasChanged(true))} /> <span /></span> Use carrier's  suggested retail rates when available
                                </div>
                                <div className="mb-3 mt-5 d-flex align-items-center">
                                    <h5>Use Dynamic Pricing</h5>
                                    <span className="cm_toggler ms-3">
                                        <input
                                            checked={hasDynamicPricing}
                                            onChange={() => (setHasDynamicPricing(!hasDynamicPricing), setHasChanged(true))}
                                            type="checkbox" />
                                        <span />
                                    </span>
                                </div>




                              {hasDynamicPricing && (
                                <div className="dynamic_price_wraper">
                                    <div className="dynamic_price" >
                                        <p className='d-inline-block mb-0 text_light fwb'>Min</p>
                                        <input
                                            style={{maxWidth: '150px' }}
                                            type='text'
                                            className='form-control d-inline-block w-auto ms-3'
                                            value={min}
                                            onChange={(e) => (setMin(e.target.value), setHasChanged(true))} />
                                        <p className='d-inline-block mb-0 ms-3 text_light fwb'>Max</p>
                                        <input
                                            style={{ maxWidth: '150px' }}
                                            type='text'
                                            className='form-control d-inline-block w-auto ms-3'
                                            value={max}
                                            onChange={(e) => (setMax(e.target.value), setHasChanged(true))} />

                                        <DynamicPricingFrom time_slots={time_slots} calback={e => (setTime_slots(e), setHasChanged(true))} />
                                    
                                    </div>
                                </div>
                              )}

                                

                                
                            </div>
                        </div>





                        <div className='d-flex align-items-baseline mt-3'>
                            <div className='col-1 font-weight-bold'><span className='me-2'>Step 3</span> <IconRightArrowLong /></div>
                            <SelectServices
                                _carriers={carriers}
                                onServicesChange={e => (setCarriers(e), setHasChanged(true))} />
                        </div>



                    </div>
                </div>


        

                <div className="d-flex footer_navigate px-5">
                    <span onClick={handleBackPage} className="btn btn-outline-dark"><IconLeftArrow /> <span className="ms-3">Back</span></span>
                    <span className="btn btn-outline-dark ms-auto"><span className="me-3">Cancel</span> <IconCrossSmall /></span>
                    <span
                        onClick={() => btnLoader ? null : handleSubmit()}
                        className="btn btn-dark ms-5">
                        <span className="me-3">Next</span> {btnLoader ? <IconSpiner /> : <IconRightArrow />}
                    </span>
                </div>
            </div>
        </div>
        
    )
}

export default ShippingSetup;
