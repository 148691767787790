import { createSlice } from '@reduxjs/toolkit';

const userProfile = JSON.parse(localStorage.getItem('userProfile'));

export const userSlice = createSlice({
  name: 'user',
  initialState: userProfile ? { ...userProfile } : {},
  reducers: {
    setUserProfileAction: (state, action) => {
      return { ...action.payload };
    },
  },
});

export const { setUserProfileAction } = userSlice.actions;

export default userSlice.reducer;
