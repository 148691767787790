import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  store: {},
  equipments: {},
  printer: {},
  carrier: {},
  shipping: {},
  dropOff: {},
  inventory: {}
};

const myStoreSlice = createSlice({
  name: 'myStore',
  initialState,
  reducers: {
    updateStoreAction: (state, action) => {
      return { ...state, store: action.payload}
    },

    updateEquipmentsAction: (state, action) => {
      return { ...state, equipments: action.payload}
    },

    updatePrinterAction: (state, action) => {
      return { ...state, printer: action.payload}
    },

    updateCarrierAction: (state, action) => {
      return { ...state, carrier: action.payload}
    },

    updateShippingAction: (state, action) => {
      return { ...state, shipping: action.payload}
    },

    updateDropOffAction: (state, action) => {
      return { ...state, dropOff: action.payload}
    },

    updateInventoryAction: (state, action) => {
      return { ...state, inventory: action.payload}
    },
    
  },
});

export const {
  updateStoreAction,
  updateEquipmentsAction,
  updatePrinterAction,
  updateCarrierAction,
  updateShippingAction,
  updateDropOffAction,
  updateInventoryAction,
} = myStoreSlice.actions;

export default myStoreSlice.reducer;
